import './index.css';
import { StyledEngineProvider } from '@mui/material/styles';
import Staking from './staking/Staking';
import { MetaMaskProvider } from 'metamask-react';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <MetaMaskProvider>
        <Staking />
      </MetaMaskProvider>
    </StyledEngineProvider>
  );
}

export default App;
